import React from 'react';
import { FormHelperText } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../hooks/useFetch';

export function SelectActivitiesOrders({ onChange, name, value, error: err, helperText }) {
    const { data, error, mutate, isFetching } = useFetch('/activity');
    const labels = data ? data.map(item => ({ label: item.name, id: item.id })) : [];

    return (
        <>
            <Autocomplete
                fullWidth
                name={name}
                options={labels}
                value={labels.filter(item => item.id === value)[0] || null}
                getOptionLabel={option => option.label}
                onChange={(e, selected) => onChange(name, selected? selected.id : '' )}
                renderInput={params => <TextField {...params} error={Boolean(err)} label="Selecione uma atividade" />}
            />
            <FormHelperText error={Boolean(err)}>{err}</FormHelperText>
        </>
    );
}
