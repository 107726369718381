import React from 'react';
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { useFetch } from '../hooks/useFetch';

export function SelectProfessional({ value, onChange, name, error: err, helperText }) {
    const { data, error, mutate, isFetching } = useFetch('/user', {
        params: {
            team: 'professional',
        },
    });

    const handleChange = e=>{
        if(e){
            onChange(e.id)
        }else{
            onChange('')
        }
    }

    return (
        <>
            <Autocomplete
                fullWidth
                name={name}
                loading={isFetching}
                options={data?.rows || []}
                defaultValue={data?.rows ? data?.rows.filter(item => item.id === Number(value))[0] : null}
                getOptionLabel={option => option.name}
                onChange={(e, selected) => handleChange(selected)}
                renderInput={params => <TextField {...params} label="Selecione um profissional" />}
            />
            <FormHelperText error={Boolean(err)}>{err}</FormHelperText>
        </>
    );
}
