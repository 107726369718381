import React, { useState } from 'react';
import ContainerGlobal from '../../../components/ContainerGlobal';
import { TextField, Paper, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import { LoadingButton } from '@mui/lab';
import { date } from 'yup/lib/locale';
// import { ActivitiesProfessionals, SelectActivities } from './SelectActivities';
import { useSnackbar } from 'notistack';
import GlobalPaper from '../../../components/GlobalPaper';
import SelectMultipleActivities from '../../../components/SelectMutipleActivities';

export function FormEditProfessionals({ data, mutate }) {
    const { enqueueSnackbar } = useSnackbar();

    const ProfessionalsFormScheema = Yup.object().shape({
        birthday: Yup.string().min(6).required('Campo obrigatório'),
        cellphone: Yup.string().min(10).required('Campo obrigatório'),
        document: Yup.string().min(10, 'Documento deve ter pelo menos 10 caracteres').required('Campo obrigatório'),
    });

    const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue } = useFormik({
        initialValues: data.Professional
            ? {
                  ...data.Professional,
                  ProfessionalActivities: data.Professional.ProfessionalActivities.map(({ activity_id }) => activity_id),
              }
            : {
                  id: data.id,
                  birthday: '',
                  cellphone: '',
                  document: '',
                  ProfessionalActivities: [],
              },
        validationSchema: ProfessionalsFormScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await api.put(`user/${values.id}/professional`, values);
                setSubmitting(false);
                enqueueSnackbar('Profissional alterado', { variant: 'success' });
                mutate();
            } catch (error) {
                setError(error);
            }
        },
    });

    const [error, setError] = useState();
    useHandleErrors(error);

    return (
        <GlobalPaper>
            <Stack direction="column" spacing={4}>
                <Typography variant="h3">Dados do profissional</Typography>

                <TextField
                    fullWidth
                    type="date"
                    label="Data de Nascimento"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={Boolean(errors.birthday)}
                    helperText={errors.birthday}
                    name="birthday"
                    onChange={handleChange}
                    value={values.birthday}
                />

                <TextField
                    fullWidth
                    label="Telefone"
                    variant="outlined"
                    sx={{ mb: '2rem' }}
                    disabled={isSubmitting}
                    error={Boolean(errors.cellphone)}
                    helperText={errors.cellphone}
                    name="cellphone"
                    onChange={handleChange}
                    value={values.cellphone}
                />

                <Stack direction="row" spacing={4}>
                    <TextField
                        fullWidth
                        label="Documento"
                        variant="outlined"
                        sx={{ mb: '2rem' }}
                        disabled={isSubmitting}
                        error={Boolean(errors.document)}
                        helperText={errors.document}
                        name="document"
                        onChange={handleChange}
                        value={values.document}
                    />

                    <SelectMultipleActivities
                        value={values.ProfessionalActivities}
                        name="ProfessionalActivities"
                        onChange={handleChange}
                        error={Boolean(errors.ProfessionalActivities)}
                    />
                </Stack>

                <Stack direction="row" spacing={4} justifyContent="flex-end">
                    <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()} size="large" variant="contained" sx={{ color: '#FAFAFA' }}>
                        Salvar
                    </LoadingButton>
                </Stack>
            </Stack>
        </GlobalPaper>
    );
}
