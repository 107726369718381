import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Separator } from './Separator';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ChatIcon from '@mui/icons-material/ChatBubble';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

import Toolbar from '@mui/material/Toolbar';

import HomeIcon from '@mui/icons-material/Home';
import UserIcon from '@mui/icons-material/PersonRounded';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Breadcrumbs, Chip, Collapse, Grid, Link, Stack } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { SupportAsside } from './SupportAsside';
import FreshActions from './FreshActions';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useApp } from '../contexts/AppContext';
const drawerWidth = 240;

function ContainerGlobal({ children, page, window }) {
    const { newChats } = useApp();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [viewSuport, setViewSuport] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigation = useNavigate();

    const { LogOut, user } = useAuth();

    const drawer = (
        <div>
            <Typography sx={{ fontWeight: '700', fontSize: '1.5rem', ml: '1.2rem', my: '2rem' }}>GEM</Typography>
            <Separator />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/dashboard')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/orders')}>
                        <ListItemIcon>
                            <SignalCellularAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ordens de Serviço" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/tracker')}>
                        <ListItemIcon>
                            <WhereToVoteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tracker" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/team')}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Equipe" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/customers')}>
                        <ListItemIcon>
                            <InsertDriveFileIcon />
                        </ListItemIcon>
                        <ListItemText primary="Clientes" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/settings')}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configurações" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigation('/')}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sair" onClick={LogOut} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Grid container display={{xs: 'inherit', md: 'flex'}} width="100%" height="100%" >
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>

                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            <IconButton edge="end" onClick={() => setViewSuport(!viewSuport)}>
                                <Badge badgeContent={newChats.length} color="error">
                                    <ChatIcon />
                                </Badge>
                            </IconButton>
                            <Chip label={user.name} icon={<UserIcon />} />
                            {/* <Typography variant="subtitle2">{user.name}</Typography>
                            <IconButton edge="end">
                                <UserIcon />
                            </IconButton> */}
                        </Stack>
                    </Toolbar>
                </AppBar>

                <Box component="nav" sx={{ flexGrow: 1, width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}>
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open>
                        {drawer}
                    </Drawer>
                </Box>

                <Box
                    component="main"
                    sx={{
                        height: 'calc(100% - 10px)',
                        overflow: 'auto',
                        position: 'relative',
                        flexGrow: 1,
                        p:4,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                    }}>
                    <Toolbar />
                    {children}
                </Box>

                <Box component="nav" sx={{ flexGrow: 1, width: { sm: '100%', md: '400px'}, flexShrink: { sm: 0 } }} aria-label="mailbox">
                    <Drawer open={viewSuport} anchor="right" variant="temporary" sx={{ width: { xs: '100%', md: '400px' } }}>
                        <Toolbar sx={{ justifyContent: 'space-between' }}>
                            <Typography variant="h3">Suporte</Typography>
                            <IconButton onClick={() => setViewSuport(!viewSuport)}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                        <SupportAsside />
                    </Drawer>
                </Box>
            </Grid>
            <FreshActions />
        </>
    );
}

// ContainerGlobal.propTypes = {
//     window: PropTypes.func,
//     page: PropTypes.string.isRequired,
// };

export default ContainerGlobal;
