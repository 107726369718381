import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer from '../../../components/LoadingContent';
import { Button, Chip, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Box } from '@mui/material';
import GlobalPaper from '../../../components/GlobalPaper';

const ViewDetailsButton = ({ params, id }) => {
    const navigation = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => navigation(`/team/${id}`)}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
    },
    {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
    },
    {
        field: 'email',
        headerName: 'E-mail',
        width: 400,
    },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     width: 200,
    //     renderCell: params => <Chip size='small' label={params.value ? 'Ativo' : 'Inativo'} color={params.value ? 'success' : 'default'} />,
    // },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default function ListUsers() {
    const navigation = useNavigate();

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const { data, error, mutate, isFetching } = useFetch('/user', {
        params: {
            limit: pageSize,
            page: page,
        },
    });

    if (isFetching) return <LoadingContainer />;

    const { rows } = data;

    return (
        <>
            {/* <Grid container direction="row" justifyContent="flex-end">
                <Button variant="contained" sx={{ color: '#FAFAFA' }} onClick={() => navigation(`/team/new`)}>
                    Criar novo usuário
                </Button>
            </Grid> */}

            <GlobalPaper elevation={3}>
                <Typography variant="h3" mb={3}>
                    Lista de Usuários e Prestadores
                </Typography>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        style={{ border: 'none' }}
                        loading={isFetching}
                        pageSize={pageSize}
                        onPageChange={newPage => setPage(newPage)}
                        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        rows={rows}
                        rowsPerPageOptions={[10, 25, 100]}
                        disableSelectionOnClick
                    />
                </Box>
            </GlobalPaper>
        </>
    );
}
