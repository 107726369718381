import { Grid, Box, Card, CardActions, CardContent, Button, Typography, TextField, Switch, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';

import ImageLoginScreen from '../../../../assets/DetailLogin.png';
import api from '../../../../services/api';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useAuth } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export function Login() {
    const navigation = useNavigate();
    const [error, setError] = useState();

    useHandleErrors(error);

    const { verifySession } = useAuth();

    const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            email: 'adm@gem.com.br',
            password: '',
        },
        onSubmit: async values => {
            try {
                const { data } = await api.post('auth/login', values);
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('user', JSON.stringify(data.user));
                verifySession();
                navigation('/dashboard');
            } catch (error) {
                setError(error);
            }
        },
    });

    return (
        <Grid container direction="row" spacing={20} justifyContent="space-between" height="100%">
            <Grid item container xs={12} sm={7} alignItems="center" justifyContent="flex-end">
                <Card
                    elevation={3}
                    sx={{
                        borderRadius: '6px',
                        width: '22rem',
                    }}>
                    <CardContent>
                        <Stack sx={{mb: 6}} direction="column">
                            <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} color="primary">
                                Bem-vindo
                            </Typography>
                            <Typography sx={{ fontSize: '0.9rem', fontWeight: '700' }} color="#A0AEC0">
                                Use suas credenciais para acessar
                            </Typography>
                        </Stack>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Endereço de e-mail"
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Senha"
                                    variant="outlined"
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={handleChange}
                                    value={values.password}
                                />
                            </Grid>
                            {/* <Typography
                            sx={{
                                mt: '1.3rem',
                                mb: '0.5rem',
                                fontWeight: '400',
                                fontSize: '0.9rem',
                            }}>
                            <Switch />
                            Lembrar no próximo acesso
                        </Typography> */}
                            <Grid item>
                                <LoadingButton
                                    onClick={handleSubmit}
                                    fullWidth
                                    sx={{ color: '#FAFAFA' }}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    variant="contained">
                                    Entrar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {/* <CardActions></CardActions> */}

                    {/* <Typography sx={{ ml: '2rem', mt: '1rem' }}>
                        Não lembra a senha?
                        <Button sx={{ color: 'primary' }}>Clique Aqui</Button>
                    </Typography> */}
                </Card>
            </Grid>

            <Grid container sm={4} sx={{ sm: 'hidden' }}>
                <Box component="img" height={700} sx={{borderRadius:5}} src={ImageLoginScreen}></Box>
            </Grid>
        </Grid>
    );
}
