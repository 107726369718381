import React from 'react';
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { useFetch } from '../../../hooks/useFetch';
import { DateRange } from '@mui/icons-material';

export function SelectCustomers({ value, onChange, name, error: err, helperText }) {
    const { data, error, mutate, isFetching } = useFetch('/customer', {
        params: {
            all: true,
        },
    });

    const labels = data ? data.map(item => ({ label: item.customer_name, id: item.id })) : [];

    return (
        <>
            <Autocomplete
                fullWidth
                name={name}
                options={labels}
                value={labels.filter(item => item.id === value)[0] || null}
                getOptionLabel={option => option.label}
                onChange={(e, selected) => onChange(name, selected? selected.id : '')}
                renderInput={params => <TextField {...params} error={Boolean(err)} label="Selecione um cliente" />}
            />
            <FormHelperText error={Boolean(err)}>{err}</FormHelperText>
        </>
    );
}
