import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { useFetch } from '../../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import LoadingContainer from '../../../components/LoadingContent';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import LoadIcon from '@mui/icons-material/Update';
import InProgressIcon from '@mui/icons-material/PublishedWithChanges';
import lodash from 'lodash';

const ViewDetailsButton = ({ params, id }) => {
    const navigation = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => navigation(`/orders/${id}`)}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
    },
    {
        field: 'Customer',
        headerName: 'Cliente',
        minWidth: 280,
        flex: 1,
        valueGetter: params => params.row.Customer.customer_name,
    },
    {
        field: 'Professional',
        headerName: 'Prestador',
        width: 150,
        valueGetter: params => params.row.Professional.User.name,
    },
    {
        field: 'Activity',
        headerName: 'Atividade',
        width: 150,
        valueGetter: params => params.row.Activity.name,
    },
    {
        field: 'Services',
        headerName: 'Andamento',
        width: 100,
        renderCell: params => {
            const openeds = lodash.countBy(params.row.Services, c => c.status).true || 0;
            return (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress size={40} variant="determinate" value={(openeds * 100) / params.row.Services.length} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Typography variant="caption" component="div" sx={{ fontSize: 11 }}>
                            {Math.round((openeds * 100) / params.row.Services.length)}%
                        </Typography>
                    </Box>
                </Box>
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: params =>
            params.value === 'CONCLUDED' ? (
                <Chip color="success" size="small" label="Concluído" icon={<CheckIcon />} />
            ) : params.value === 'IN_PROGRESS' ? (
                <Chip size="small" color="info" label="Em andamento" icon={<InProgressIcon />} />
            ) : (
                <Chip size="small" icon={<LoadIcon />} label="Aguardando" />
            ),
    },
    {
        field: 'actions',
        type: 'actions',
        width: 150,
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default function OrdersTable({ data, isFetching, pageSize, setPage, setPageSize }) {
    
    

    if (isFetching) return <LoadingContainer />;
    const { rows } = data;
    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
                style={{ border: 'none' }}
                loading={isFetching}
                columns={columns}
                pageSize={pageSize}
                onPageChange={newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rows={rows}
                rowsPerPageOptions={[10, 25, 100]}
                disableSelectionOnClick
            />
        </Box>
    );
}
