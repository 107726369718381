import React, { useState } from 'react';
import ContainerGlobal from '../../../components/ContainerGlobal';
import { TextField, Stack, FormControl, InputLabel, Select, MenuItem, Chip, Box, OutlinedInput, FormHelperText, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import GlobalPaper from '../../../components/GlobalPaper';

export function NewUser() {
    const navigation = useNavigate();
    // Envio do Formulário Formik ===========

    const costumerFormScheema = Yup.object().shape({
        name: Yup.string().min(10, 'Nome do Responsável Incompleto').required('Campo obrigatório'),
        team: Yup.array().min(1, 'Selecione um time.').required('Campo obrigatório'),
        email: Yup.string().email('E-mail inválido.').required('Campo obrigatório'),
        password: Yup.string().min(6, 'Coloque no mínimo 6 caracteres.').required('Campo obrigatório'),
    });

    const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            email: '',
            team: [],
            password: '',
        },
        validationSchema: costumerFormScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const response = await api.post('/user', values);
                setSubmitting(false);
                handleReset();
                navigation(`/team/${response.data.data.id}`);
            } catch (error) {
                setError(error);
            }
        },
    });

    const [error, setError] = useState();
    useHandleErrors(error);

    return (
        <GlobalPaper elevation={3} >

            <Typography variant="h3" sx={{mb: '2rem'}}>Criar novo usuário</Typography>

            <Stack direction="column" spacing={4}>
                <TextField
                    fullWidth
                    label="Nome"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                />

                <TextField
                    fullWidth
                    label="E-mail"
                    variant="outlined"
                    sx={{ mb: '2rem' }}
                    disabled={isSubmitting}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                />

                <Stack direction="row" spacing={4} sx={{ mb: '2rem' }}>
                    <FormControl sx={{ width: '50%' }}>
                        <InputLabel error={Boolean(errors.team)} id="demo-multiple-chip-label">
                            Selecione seu perfil
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            name="team"
                            disabled={isSubmitting}
                            value={values.team}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Selecione seu perfil" />}
                            error={Boolean(errors.team)}
                            renderValue={selected => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map(value => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}>
                            {['admin', 'professional'].map(name => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={Boolean(errors.team)} disabled={Boolean(errors.team)}>
                            {errors.team}
                        </FormHelperText>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Senha"
                        type="password"
                        variant="outlined"
                        disabled={isSubmitting}
                        sx={{ mb: '3rem' }}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                    />
                </Stack>

                <Stack direction="row" spacing={4} justifyContent="flex-end">
                    <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()} size="large" variant="contained">
                        Salvar
                    </LoadingButton>
                </Stack>
            </Stack>
        </GlobalPaper>
    );
}
