import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import DialogAddAsk from './DialogAddAsk';
import { Box, Button, Chip, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApp } from '../../../contexts/AppContext';

const ViewDetailsButton = ({ params, item }) => {
    const { setEditAsk, setOpenAsk } = useApp();
    return (
        <Button
            variant="outlined"
            onClick={() => {
                setEditAsk(item);
                setOpenAsk(true);
            }}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Editar
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'question',
        headerName: 'Checklist',
        minWidth: 200,
        flex: 0.5,
    },
    // {
    //     field: 'ActivityAsks',
    //     headerName: 'Atividades',
    //     minWidth: 200,
    //     flex: 1,
    //     renderCell: params => (
    //         <Stack direction="row" spacing={2}>
    //             {params.row.ActivityAsks.map(item => (
    //                 `${item.Activity.name}, `
    //             ))}
    //         </Stack>
    //     ),
    // },
    {
        field: 'required',
        headerName: 'Obrigatória',
        width: 120,
        valueGetter: params => (JSON.parse(params.row.required) ? 'Sim' : 'Não'),
    },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} item={params.row} />,
    },
];

export default function ListAsks() {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const { setEditAsk, setOpenAsk, openAsk, editAsk } = useApp();

    const { data, error, mutate, isFetching } = useFetch('ask', {
        params: {
            limit: pageSize,
            page: page,
            paged: true,
        },
    });

    if (isFetching) return <LoadingContainer />;

    const { rows, pagination } = data;

    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    columns={columns}
                    //paginationMode="server"
                    loading={isFetching}
                    pageSize={pageSize}
                    onPageChange={newPage => setPage(newPage)}
                    onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                    rows={rows}
                    rowsPerPageOptions={[10, 25, 100]}
                    disableSelectionOnClick
                />
            </Box>

            <DialogAddAsk
                mutate={mutate}
                open={openAsk}
                handleClose={() => {
                    setEditAsk();
                    setOpenAsk(false);
                }}
                data={editAsk}
            />
        </>
    );
}
